import { Box, Modal, ModalClose, Sheet, Typography } from "@mui/joy";

interface IProps {
  isOpen: boolean;
  title: string;
  onClose: () => void;
  actions?: React.ReactNode;
  width?: number | string;
  children?: React.ReactNode;
  closeOnBackdropClick?: boolean;
}

export default function BaseModal({
  isOpen,
  onClose,
  title,
  actions,
  width = 480,
  children,
  closeOnBackdropClick = true,
}: IProps) {
  const handleClose = (e: any, reason: string) => {
    if (reason === "backdropClick" && !closeOnBackdropClick) {
      return;
    }

    onClose();
  };
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        '.MuiSheet-root': {
          outline: "none",
        }
      }}
    >
      <Sheet
        sx={{
          width: width,
          borderRadius: "md",
          p: {
            xs: 1,
            md: 3,
          },
          boxShadow: "lg",
        }}
      >
        <ModalClose variant="plain" sx={{
          m: {
            xs: 0,
            md: 1,
          }
        }} />
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: 17,
          }}
        >
          {title}
        </Typography>

        <Box
          sx={{
            mt: 2,
          }}
        >
          {children}
        </Box>
        {!!actions && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 2,
            }}
          >
            {actions}
          </Box>
        )}
      </Sheet>
    </Modal>
  );
}
