import { InstallDesktopOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/joy';
import { Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';

declare global {
  interface Window {
    deferredPrompt: any;
  }
}

const InstallAppButton = () => {
  const [isInstallable, setIsInstallable] = useState(false);
  useEffect(() => {
    const handler = (e: any) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Store the event globally so it's not lost on re-renders
      window.deferredPrompt = e;
      setIsInstallable(true); // Enable the install button
    };

    // Listen for the 'beforeinstallprompt' event
    window.addEventListener('beforeinstallprompt', handler);

    // Check if the event was already captured in the window object
    if (window.deferredPrompt) {
      setIsInstallable(true); // Make sure the button is still available
    }

    return () => {
      window.removeEventListener('beforeinstallprompt', handler);
    };
  }, []);

  const handleInstallClick = () => {
    const promptEvent = window.deferredPrompt;
    if (promptEvent) {
      // Show the install prompt
      promptEvent.prompt();
      // Wait for the user to respond to the prompt
      promptEvent.userChoice.then((choiceResult: any) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
          // Reset the deferredPrompt, the prompt can only be used once
          window.deferredPrompt = null;
          setIsInstallable(false); // Hide the button after installing
        } else {
          console.log('User dismissed the install prompt');
        }

      });
    }
  };

  if (!isInstallable) return null; // Hide the button if not installable

  return (
    <IconButton color="primary" size="sm" onClick={handleInstallClick}>
      <Tooltip title="Click to install platform">
        <InstallDesktopOutlined fontSize="small" />
      </Tooltip>
    </IconButton>
  );
};

export default InstallAppButton;
