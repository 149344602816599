import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

// Extend dayjs with the plugins
dayjs.extend(utc);
dayjs.extend(timezone);

// Set default timezone to California (Pacific Time)
dayjs.tz.setDefault('America/Los_Angeles');

export default dayjs;