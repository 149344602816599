import { createSlice } from "@reduxjs/toolkit";
import { addListBuilderCase, addListRemoteBuilderCase } from "redux-store/middlewares/builderUtils";
import { createCallApiAsyncAction } from "redux-store/middlewares/createAsyncAction";
import {
  checkInvoiceCustomerApi,
  createCustomerApi,
  deleteCustomerApi,
  getCustomerAddressListApi,
  getDetailCustomerApi,
  getDetailCustomerDropdownApi,
  getListCustomerContactApi,
  listCustomerApi,
  updateCustomerApi,
} from "services/customer.service";
import { ICustomer } from "types/customer";
import { ICustomerDropdown } from "types/customer-portal-so";

const TYPE = "CUSTOMER";

export interface ICustomerState {
  list: ICustomer[];
  listTotalItems: number;
  customerDropdown: ICustomerDropdown[];
}

const initialState: ICustomerState = {
  list: [],
  listTotalItems: 0,
  customerDropdown: [],
};

export const createCustomer = createCallApiAsyncAction(
  TYPE + "/createCustomer",
  createCustomerApi
);

export const updateCustomer = createCallApiAsyncAction(
  TYPE + "/updateCustomer",
  updateCustomerApi
);

export const deleteCustomer = createCallApiAsyncAction(
  TYPE + "/deleteCustomer",
  deleteCustomerApi
);

export const getListCustomer = createCallApiAsyncAction(
  TYPE + "/getListCustomer",
  listCustomerApi
);

export const getDetailCustomer = createCallApiAsyncAction(
  TYPE + "/getDetailCustomer",
  getDetailCustomerApi
);

export const getDetailCustomerDropdown = createCallApiAsyncAction(
  TYPE + "/getDetailCustomerDropdown",
  getDetailCustomerDropdownApi
);

export const getCustomerAddressList = createCallApiAsyncAction(
  TYPE + "/getCustomerAddressList",
  getCustomerAddressListApi
);

export const checkInvoiceCustomer = createCallApiAsyncAction(
  TYPE + "/checkInvoiceCustomer",
  checkInvoiceCustomerApi
);

//contact
export const getListCustomerContact = createCallApiAsyncAction(
  TYPE + "/getListCustomerContact",
  getListCustomerContactApi
);

const slice = createSlice({
  name: TYPE,
  initialState,
  extraReducers: (builder) => {
    addListRemoteBuilderCase({
      builder,
      fetchAction: getListCustomer,
      stateKey: "list",
    });
    addListBuilderCase({
      builder,
      fetchAction: getDetailCustomerDropdown,
      stateKey: "customerDropdown",
    });

    builder.addCase(getDetailCustomer.fulfilled, (state, action) => {
      const customer = action.payload?.response?.apiStatus
        ? action.payload?.response?.data
        : null;
      if (!customer) return;
      const index = state.list.findIndex((item) => item.id === customer.id);
      if (index > -1) {
        state.list[index] = customer;
      }
    });
  },
  reducers: {},
});

export default slice.reducer;
