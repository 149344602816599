import { IRemoteListResponse } from "types/common";
import { ICustomer } from "types/customer";
import {
  ICustomerAddress,
  ICustomerContact,
  ICustomerDropdown,
} from "types/customer-portal-so";
import { Request } from "utils/request";

export const listCustomerApi = (payload: any) => {
  return Request.call<{ data: IRemoteListResponse<ICustomer> }>({
    url: "/customer/list",
    method: "PUT",
    data: payload,
  });
};

export const getAllCustomerApi = async (payload: any) => {
  const customerList: ICustomer[] = [];
  let startPage = 1;

  while (true) {
    const response = await listCustomerApi({
      ...payload,
      page: startPage++,
      pageSize: 50,
    });

    if (!response?.data?.data?.length) {
      break;
    }

    customerList.push(...response.data.data);
  }

  return customerList;
};

export const createCustomerApi = (payload: ICustomer) => {
  return Request.call<{ data: ICustomer }>({
    url: "/customer/create",
    method: "PUT",
    data: payload,
  });
};

export const updateCustomerApi = (payload: ICustomer) => {
  return Request.call<{ data: ICustomer }>({
    url: "/customer/update",
    method: "PUT",
    data: payload,
  });
};

export const deleteCustomerApi = (payload: { customer_id: number }) => {
  return Request.call<{ data: any }>({
    url: "/customer/delete",
    method: "PUT",
    data: payload,
  });
};

export const getDetailCustomerApi = (payload: { id: any }) => {
  return Request.call<{ data: ICustomer }>({
    url: "/customer/detail/" + payload.id,
    method: "GET",
  });
};

export const getDetailCustomerDropdownApi = (payload: { isLead?: boolean }) => {
  return Request.call<{ data: ICustomerDropdown[] }>({
    url: "/customer/list/dropdown" + (payload.isLead ? "?is_lead=true" : ""),
    method: "GET",
  });
};

export const getCustomerAddressListApi = (payload: { customer_id: any }) => {
  return Request.call<{ data: ICustomerAddress[] }>({
    url: "/customer/address/list",
    method: "PUT",
    data: payload,
  });
};

// contact
export const getListCustomerContactApi = (payload: { customer_id: any }) => {
  return Request.call<{ data: ICustomerContact[] }>({
    url: "/customer/contact/list",
    method: "PUT",
    data: payload,
  });
};

export const checkInvoiceCustomerApi = (payload: { customer_id: any }) => {
  return Request.call<{
    data: {
      due: number;
    };
  }>({
    url: "/customer/check-due-invoice",
    method: "PUT",
    data: payload,
  });
};
