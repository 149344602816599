import { createSlice } from "@reduxjs/toolkit";
import { addListBuilderCase } from "redux-store/middlewares/builderUtils";
import { createCallApiAsyncAction } from "redux-store/middlewares/createAsyncAction";
import {
  actionContractApi,
  createContractApi,
  getDetailContractApi,
  getListContractApi,
  updateContractApi,
} from "services/contract.service";
import { IContract } from "types/contract";

const TYPE = "CONTRACT";

export interface IContractState {
  list: IContract[];
}

const initialState: IContractState = {
  list: [],
};

export const getListContract = createCallApiAsyncAction(
  TYPE + "/getListContract",
  getListContractApi
);

export const getDetailContract = createCallApiAsyncAction(
  TYPE + "/getDetailContract",
  getDetailContractApi
);

export const updateContract = createCallApiAsyncAction(
  TYPE + "/updateContract",
  updateContractApi
);

export const actionContract = createCallApiAsyncAction(
  TYPE + "/actionContract",
  actionContractApi
);

export const createContract = createCallApiAsyncAction(
  TYPE + "/createContract",
  createContractApi
);

const slice = createSlice({
  name: TYPE,
  initialState,
  extraReducers: (builder) => {
    addListBuilderCase({
      builder,
      fetchAction: getListContract,
      stateKey: "list",
    });
  },
  reducers: {},
});

export default slice.reducer;
