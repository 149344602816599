import { createSlice } from "@reduxjs/toolkit";
import { addListBuilderCase } from "redux-store/middlewares/builderUtils";
import { createCallApiAsyncAction } from "redux-store/middlewares/createAsyncAction";
import {
  getListSalesCustomerDropshipReportApi,
  getListSalesCustomerReportApi,
  getListSalesItemsReportApi,
  getListSalesMonthReportApi,
} from "services/sales-report.service";
import { ICustomerSalesReport } from "types/sales-report";

const TYPE = "SALES_REPORT";

export interface ISalesReportState {
  customerList: ICustomerSalesReport[];
  customerDropshipList: ICustomerSalesReport[];
  itemsList: ICustomerSalesReport[];
  monthList: ICustomerSalesReport[];
}

const initialState: ISalesReportState = {
  customerList: [],
  customerDropshipList: [],
  itemsList: [],
  monthList: [],
};

export const getListSalesCustomerReport = createCallApiAsyncAction(
  TYPE + "/getListSalesCustomerReport",
  getListSalesCustomerReportApi
);

export const getListSalesItemsReport = createCallApiAsyncAction(
  TYPE + "/getListSalesItemsReport",
  getListSalesItemsReportApi
);

export const getListSalesCustomerDropshipReport = createCallApiAsyncAction(
  TYPE + "/getListSalesCustomerDropshipReport",
  getListSalesCustomerDropshipReportApi
);

export const getListSalesMonthReport = createCallApiAsyncAction(
  TYPE + "/getListSalesMonthReport",
  getListSalesMonthReportApi
);

const slice = createSlice({
  name: TYPE,
  initialState,
  extraReducers: (builder) => {
    addListBuilderCase({
      builder,
      fetchAction: getListSalesCustomerReport,
      stateKey: "customerList",
    });

    addListBuilderCase({
      builder,
      fetchAction: getListSalesItemsReport,
      stateKey: "itemsList",
    });

    addListBuilderCase({
      builder,
      fetchAction: getListSalesCustomerDropshipReport,
      stateKey: "customerDropshipList",
    });

    addListBuilderCase({
      builder,
      fetchAction: getListSalesMonthReport,
      stateKey: "monthList",
    });
  },
  reducers: {},
});

export default slice.reducer;
