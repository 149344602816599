import EventEmitter from "events";
import { v4 as uuidv4 } from "uuid";

type IPayload = any;
const AppEventEmitter = new EventEmitter();
export const tabUUID = uuidv4();

export enum AppEventTypes {
  PO_STATUS_CHANGE = "PO_STATUS_CHANGE",
  SO_STATUS_CHANGE = "SO_STATUS_CHANGE",
  FQ_STATUS_CHANGE = "FQ_STATUS_CHANGE",
  NOTI_PULL = "NOTI_PULL",
  FULL_SCREEN_MODAL_REFRESH = "FULL_SCREEN_MODAL_REFRESH",
}

const AppEvent = {
  on: (event: AppEventTypes, listener: (payload?: IPayload) => void) => {
    AppEventEmitter.on(event, listener);
  },
  off: (event: AppEventTypes, listener: (payload?: IPayload) => void) => {
    AppEventEmitter.off(event, listener);
  },
  emit: (event: AppEventTypes, payload?: IPayload) => {
    AppEventEmitter.emit(event, payload);
  },
  types: AppEventTypes,
};

export default AppEvent;
