import {
  IProduct,
  IProductItem,
  IProductWithId,
  TCustomerProductItemList,
  TProductItemList,
} from "types/product";
import { Request } from "utils/request";

export const createProductApi = (payload: IProduct) => {
  return Request.call<{ data: IProductWithId }>({
    url: "/product/create",
    method: "PUT",
    data: payload,
  });
};

export const importProductApi = (payload: { products_chunk: any[] }) => {
  return Request.call<{ data: any }>({
    url: "/product/import",
    method: "PUT",
    data: payload,
  });
};

export const listProductApi = () => {
  return Request.call<{ data: IProductWithId[] }>({
    url: "/product/list",
    method: "GET",
  });
};

export const sendPricingRequestApi = () => {
  return Request.call<{ data: any }>({
    url: "/product/pricing-request",
    method: "GET",
  });
};

export const getListProductExportApi = () => {
  return Request.call<{ data: any }>({
    url: "/product/list/export",
    method: "GET",
  });
};

export const getDetailProductApi = (payload: { id: any }) => {
  return Request.call<{ data: IProductWithId }>({
    url: "/product/detail/" + payload.id,
    method: "GET",
  });
};

export const deActiveProductApi = (payload: {
  id: string;
}) => {
  return Request.call<{ data: {} }>({
    url: "/product/deactive/",
    method: "PUT",
    data: payload,
  });
};


export const createProductItemApi = (payload: {
  id: string;
  items: IProductItem[];
  name?: string;
  itemType?: string;
  itemComp?: number;
  description?: string;
  sub_items?: any[];
}) => {
  return Request.call<{ data: {} }>({
    url: "/product/update/",
    method: "PUT",
    data: payload,
  });
};

export const getProductItemsApi = () => {
  return Request.call<{ data: TProductItemList }>({
    url: "/product/items",
    method: "GET",
  });
};

export const getItemInStockApi = (payload: { item_id: any; so_id?: any }) => {
  return Request.call<{ data: { count: number; itemsBom: any[] } }>({
    url: "/product/items/instock/",
    method: "PUT",
    data: payload,
  });
};

export const getCustomerProductItemsApi = () => {
  return Request.call<{ data: TCustomerProductItemList }>({
    url: "/customer-portal/product/items",
    method: "GET",
  });
};
