import { createSlice } from "@reduxjs/toolkit";
import { addListBuilderCase } from "redux-store/middlewares/builderUtils";
import { createCallApiAsyncAction } from "redux-store/middlewares/createAsyncAction";
import {
  boxDeleteSerialNumberApi,
  boxGetSerialNumberApi,
  createWarehouseApi,
  createWarehouseAreaApi,
  deleteWarehouseAreaApi,
  getDetailWarehouseApi,
  getWarehouseAreasApi,
  itemBoxPackingV2Api,
  itemPalletPackingV2Api,
  listWarehouseApi,
  palletDeleteSerialNumberApi,
  palletGetSerialNumberApi,
  receiveItemPalletV2Api,
  scanItemAllApi
} from "services/warehouse.service";
import { IArea, IWarehouse } from "types/warehouse";

const TYPE = "WAREHOUSE";

const initialState: {
  list: IWarehouse[];
  warehouseAreas: IArea[];
} = {
  list: [],
  warehouseAreas: [],
};

export const createWarehouse = createCallApiAsyncAction(
  TYPE + "/createWarehouse",
  createWarehouseApi
);
export const getListWarehouse = createCallApiAsyncAction(
  TYPE + "/getListWarehouse",
  listWarehouseApi
);

export const getDetailWarehouse = createCallApiAsyncAction(
  TYPE + "/getDetailWarehouse",
  getDetailWarehouseApi
);

export const createWarehouseArea = createCallApiAsyncAction(
  TYPE + "/createWarehouseArea",
  createWarehouseAreaApi
);

export const getWarehouseAreas = createCallApiAsyncAction(
  TYPE + "/getWarehouseAreas",
  getWarehouseAreasApi
);


export const receiveItemPalletV2 = createCallApiAsyncAction(
  TYPE + "/receiveItemPalletV2",
  receiveItemPalletV2Api
);

// box
export const itemBoxPackingV2 = createCallApiAsyncAction(
  TYPE + "/itemBoxPackingV2",
  itemBoxPackingV2Api
);
export const boxGetSerialNumber = createCallApiAsyncAction(
  TYPE + "/boxGetSerialNumber",
  boxGetSerialNumberApi
);

export const boxDeleteSerialNumber = createCallApiAsyncAction(
  TYPE + "/boxDeleteSerialNumber",
  boxDeleteSerialNumberApi
);
// end box

export const itemPalletPackingV2 = createCallApiAsyncAction(
  TYPE + "/itemPalletPackingV2",
  itemPalletPackingV2Api
);

export const palletGetSerialNumber = createCallApiAsyncAction(
  TYPE + "/palletGetSerialNumber",
  palletGetSerialNumberApi
);

export const palletDeleteSerialNumber = createCallApiAsyncAction(
  TYPE + "/palletDeleteSerialNumber",
  palletDeleteSerialNumberApi
);

export const scanItemAll = createCallApiAsyncAction(
  TYPE + "/scanItemAll",
  scanItemAllApi
);

export const deleteWarehouseArea = createCallApiAsyncAction(
  TYPE + "/deleteWarehouseArea",
  deleteWarehouseAreaApi
);

const slice = createSlice({
  name: TYPE,
  initialState,
  extraReducers: (builder) => {
    addListBuilderCase({
      builder,
      fetchAction: getListWarehouse,
      stateKey: "list",
    });

    addListBuilderCase({
      builder,
      fetchAction: getWarehouseAreas,
      stateKey: "warehouseAreas",
    });
  },
  reducers: {},
});

export default slice.reducer;
