import { ICustomerSalesReport } from "types/sales-report";
import { Request } from "utils/request";

export const getListSalesCustomerReportApi = (payload: {
  fromDate: string;
  toDate: string;
  customer_id?: number | undefined;
  item_id?: number | undefined;
}) => {
  return Request.call<{ data: ICustomerSalesReport[] }>({
    url: "/sales-report/customer",
    method: "PUT",
    data: payload,
  });
};

export const getListSalesCustomerDropshipReportApi = (payload: {
  fromDate: string;
  toDate: string;
  customer_id?: number | undefined;
  item_id?: number | undefined;
}) => {
  return Request.call<{ data: ICustomerSalesReport[] }>({
    url: "/sales-report/customer/dropship",
    method: "PUT",
    data: payload,
  });
};

export const getListSalesItemsReportApi = (payload: {
  fromDate: string;
  toDate: string;
  customer_id?: number | undefined;
  item_id?: number | undefined;
}) => {
  return Request.call<{ data: ICustomerSalesReport[] }>({
    url: "/sales-report/item",
    method: "PUT",
    data: payload,
  });
};

export const getListSalesMonthReportApi = (payload: {
  fromDate: string;
  toDate: string;
  customer_id?: number | undefined;
  item_id?: number | undefined;
}) => {
  return Request.call<{ data: ICustomerSalesReport[] }>({
    url: "/sales-report/month",
    method: "PUT",
    data: payload,
  });
};


