import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { ReactNode } from "react";
import { createCallApiAsyncAction } from "redux-store/middlewares/createAsyncAction";
import { persistEncryptReducer } from "redux-store/redux-utils";
import {
  createDocumentFromSourceIdApi,
  deleteDocumentApi,
  downloadDocumentApi,
  getDocumentFromSourceIdApi,
  getSignContractApi,
  signContractApi,
} from "services/common.service";

export type IAppLang = "en" | "vi";

export type IFilterName =
  | "SO_LIST"
  | "BACK_ODER_SO_LIST"
  | "PO_LIST"
  | "ASSISTED_PO_LIST"
  | "INVOICE_LIST"
  | "CUSTOMER_LIST"
  | "LEAD_LIST"
  | "QUOTATION_LIST";

export interface ICommonState {
  lang: IAppLang;
  ready: boolean;
  loading: boolean;
  isOpenMenu: boolean;
  breadcrumb:
    | {
        label: string | ReactNode;
        link?: string;
      }[]
    | null;
  triggerReload?: number;
  searchFilter: {
    SO_LIST: any;
    PO_LIST: any;
    ASSISTED_PO_LIST: any;
    INVOICE_LIST: any;
    CUSTOMER_LIST: any;
    LEAD_LIST: any;
    BACK_ODER_SO_LIST: any;
    QUOTATION_LIST: any;
  };
  sortOrderState: {
    [key: string]: {
      key: string;
      order: "asc" | "desc";
    }[];
  };
}

const TYPE = "COMMON";

export const getDocumentFromSourceId = createCallApiAsyncAction(
  TYPE + "/getDocumentFromSourceId",
  getDocumentFromSourceIdApi
);

export const deleteDocument = createCallApiAsyncAction(
  TYPE + "/deleteDocument",
  deleteDocumentApi
);

export const downloadDocument = createCallApiAsyncAction(
  TYPE + "/downloadDocument",
  downloadDocumentApi
);

export const getSignContract = createCallApiAsyncAction(
  TYPE + "/getSignContract",
  getSignContractApi
);

export const signContract = createCallApiAsyncAction(
  TYPE + "/signContract",
  signContractApi
);

export const createDocumentFromSourceId = createCallApiAsyncAction(
  TYPE + "/createDocumentFromSourceId",
  createDocumentFromSourceIdApi
);

const initialState: ICommonState = {
  lang: "en",
  ready: false,
  isOpenMenu: false,
  loading: false,
  breadcrumb: null,
  triggerReload: 0,
  searchFilter: {
    SO_LIST: {},
    PO_LIST: {},
    ASSISTED_PO_LIST: {},
    INVOICE_LIST: {},
    CUSTOMER_LIST: {},
    LEAD_LIST: {},
    BACK_ODER_SO_LIST: {},
    QUOTATION_LIST: {},
  },
  sortOrderState: {},
};

const slice = createSlice({
  name: TYPE,
  initialState,
  reducers: {
    setBreadcrumb: (
      state,
      action: PayloadAction<ICommonState["breadcrumb"]>
    ) => {
      state.breadcrumb = action.payload;
    },

    changeLang: (state, action: PayloadAction<IAppLang>) => {
      state.lang = action.payload;
    },
    changeLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setReady: (state, action: PayloadAction<boolean>) => {
      state.ready = action.payload;
    },
    setMenuState: (state, action: PayloadAction<boolean>) => {
      state.isOpenMenu = action.payload;
    },
    reloadApp: (state) => {
      state.triggerReload = Date.now();
    },
    savePageFilter: (
      state,
      action: PayloadAction<{
        name: IFilterName;
        data: any;
      }>
    ) => {
      state.searchFilter[action.payload.name] = action.payload.data;
    },
    saveOrderState: (
      state,
      action: PayloadAction<{
        hashKey: string;
        data: {
          key: string;
          order: "asc" | "desc";
        }[];
      }>
    ) => {
      state.sortOrderState[action.payload.hashKey] = action.payload.data;
    },
  },
});

export const {
  changeLang,
  setReady,
  changeLoading,
  setBreadcrumb,
  setMenuState,
  reloadApp,
  savePageFilter,
  saveOrderState,
} = slice.actions;

export default persistEncryptReducer(TYPE, slice.reducer, [
  "isOpenMenu",
  "sortOrderState",
]) as typeof slice.reducer;
