import { ITask } from "types/task";
import { Request } from "utils/request";

export const listTaskApi = () => {
  return Request.call<{ data: ITask[] }>({
    url: "/task/list",
    method: "GET",
  });
};

export const createTaskApi = (payload: ITask) => {
  return Request.call<{ data: ITask }>({
    url: "/task/create",
    method: "PUT",
    data: payload,
  });
};

export const updateTaskApi = (payload: ITask) => {
  return Request.call<{ data: ITask }>({
    url: "/task/update",
    method: "PUT",
    data: payload,
  });
};

export const getDetailTaskApi = (payload: { id: any }) => {
  return Request.call<{ data: ITask }>({
    url: "/task/" + payload.id,
    method: "GET",
  });
};
