import { InfoOutlined } from "@mui/icons-material";
import ClearIcon from "@mui/icons-material/Clear";
import { Box, Button, IconButton, Typography } from "@mui/joy";
import {
  CustomContentProps,
  SnackbarContent,
  SnackbarProvider,
  closeSnackbar,
  enqueueSnackbar,
} from "notistack";
import { forwardRef, useState } from "react";
import { compareVersions } from "./common";

let lastVersionWarning = 0;
const REMINDER_INTERVAL = 1000 * 60 * 1; // 1 min
const warningUpdateNewVersion = (serverAppVersion: string) => {
  try {
    const clientAppVersion = process.env.REACT_APP_PUBLIC_VERSION;
    if (
      serverAppVersion &&
      clientAppVersion &&
      Date.now() - lastVersionWarning > REMINDER_INTERVAL
    ) {
      if (compareVersions(serverAppVersion, clientAppVersion) === 1) {
        lastVersionWarning = Date.now();
        setTimeout(() => {
          enqueueSnackbar(serverAppVersion, {
            variant: "versionWarning" as any,
            persist: true,
          });
        }, 2000);
      }
    }
  } catch (error) {
    console.log(error);
  }
};
const NewVersionActions = ({ snackbarId }: any) => {
  const [loading, setLoading] = useState(false);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
        ml: "auto",
      }}
    >
      <Button
        loading={loading}
        onClick={() => {
          setLoading(true);
          caches.keys().then((cacheNames) => {
            console.log("clearing cache", cacheNames);
            return Promise.all(
              cacheNames.map((cache) => {
                return caches.delete(cache);
              })
            );
          });

          setTimeout(() => {
            window.location.replace("/");
          }, 2000);
        }}
        size="sm"
        variant="plain"
      >
        Update
      </Button>
      <IconButton
        onClick={() => {
          closeSnackbar(snackbarId);
        }}
        size="sm"
      >
        <ClearIcon className="notistack-var-icon" />
      </IconButton>
    </Box>
  );
};
const VersionWarningCmp = forwardRef<HTMLDivElement, CustomContentProps>(
  (props, ref) => {
    const { id, message, ...other } = props;
    return (
      <SnackbarContent ref={ref} role="alert" {...other}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            border: "1px solid var(--joy-palette-warning-500)",
            borderRadius: 4,
            width: 400,
            padding: "8px 16px",
            backgroundColor: (theme) => theme.palette.background.popup,
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: 15,
              }}
              color="warning"
            >
              The version <strong>{message}</strong> is available. Please update
              the latest version.
            </Typography>
          </Box>
          <NewVersionActions snackbarId={id} />
        </Box>
      </SnackbarContent>
    );
  }
);

const action = (snackbarId: any) => (
  <>
    <IconButton
      onClick={() => {
        closeSnackbar(snackbarId);
      }}
      size="sm"
    >
      <ClearIcon className="notistack-var-icon" />
    </IconButton>
  </>
);
const notification = {
  success: (
    message?: string,
    options?: {
      autoHideDuration?: number;
    }
  ) => {
    try {
      enqueueSnackbar(message || "Action success", {
        variant: "success",
        action,
        ...options,
      });
    } catch (error) {
      console.log(error);
    }
  },
  error: (message?: string) => {
    try {
      enqueueSnackbar(message || 'Action fail', {
        variant: "error",
        action,
      });
    } catch (error) {
      console.log(error);
    }
  },
  warning: (
    message: string,
    options?: {
      autoHideDuration?: number;
    }
  ) => {
    try {
      enqueueSnackbar(message, {
        variant: "warning",
        action,
        ...options,
      });
    } catch (error) {
      console.log(error);
    }
  },
  newVersionWarning: warningUpdateNewVersion,
  noti: ({
    title,
    body,
    action,
  }: {
    title: string;
    body: string;
    action?: () => void;
  }) => {
    try {
      enqueueSnackbar(title, {
        variant: "noti" as any,
        persist: true,
        notiBody: body,
        notiAction: action,
      });
    } catch (error) {
      console.log(error);
    }
  },
};

interface PushNotiProps extends CustomContentProps {
  notiBody: string;
  notiAction?: () => void;
}

const PushNotificationCmp = forwardRef<HTMLDivElement, PushNotiProps>(
  (props, ref) => {
    const { id, message, notiBody, notiAction, ...other } = props;
    return (
      <SnackbarContent ref={ref} role="alert" {...other}>
        <Box
          onClick={() => {
            if (notiAction) {
              notiAction();
              closeSnackbar(id);
            }
          }}
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            border: "1px solid var(--joy-palette-warning-500)",
            borderRadius: 4,
            width: 400,
            padding: "8px 16px",
            backgroundColor: (theme) => theme.palette.background.popup,
            cursor: notiAction ? "pointer" : "default",
          }}
        >
          <InfoOutlined
            sx={{
              color: "var(--joy-palette-warning-500)",
            }}
          />
          <Box>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: 16,
              }}
              color="warning"
            >
              {message}
            </Typography>
            <Typography
              sx={{
                fontSize: 14,
              }}
              color="warning"
            >
              {notiBody}
            </Typography>
          </Box>
          <IconButton
            sx={{
              ml: "auto",
            }}
            color="warning"
            onClick={(e) => {
              closeSnackbar(id);
              e.stopPropagation();
            }}
            size="sm"
          >
            <ClearIcon className="notistack-var-icon" />
          </IconButton>
        </Box>
      </SnackbarContent>
    );
  }
);

export const NotificationProvider = ({ children }: any) => {
  return (
    <SnackbarProvider
      maxSnack={5}
      hideIconVariant={true}
      autoHideDuration={4000}
      anchorOrigin={{
        horizontal: "right",
        vertical: "top",
      }}
      classes={{
        containerRoot: "noti-wrapper-root",
      }}
      Components={{
        // @ts-ignore
        noti: PushNotificationCmp,
        versionWarning: VersionWarningCmp,
      }}
    >
      {children}
    </SnackbarProvider>
  );
};

export default notification;
