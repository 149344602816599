import { Box, Button, Card, CardActions, CardContent, Typography } from "@mui/joy";
import BaseInput from "components/BaseInput";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux-store";
import { saveSecretCode } from "redux-store/reducers/auth";
import ROUTERS from "routers/constants";
import notification from "utils/notification";

const CompanyVerifyPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(false);
  const [secretCode, setSecretCode] = useState('');
  const handleSubmitSecretCode = () => {
    setLoading(true);
    dispatch(saveSecretCode({
      secret_code: secretCode
    })).unwrap().then(rs => {
      setLoading(false);
      if (rs.response.apiStatus) {
        notification.success();
        navigate(ROUTERS.DASHBOARD);
      } else {
        notification.error(rs.response?.message);
      }
    })
  }
  return <Box sx={{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    width: "100vw",
  }}>
    <Card
      sx={{
        textAlign: 'center',
        alignItems: 'center',
        width: 310,
        // to make the demo resizable
        overflow: 'auto',
      }}
    >

      <img
        width={100}
        style={{ marginTop: "12px" }}
        src={require("assets/images/shield.png")}
      />
      <Typography level="title-lg" sx={{ mt: 1 }}>
        2-Step Verification
      </Typography>
      <CardContent sx={{ maxWidth: '39ch', display: "flex", justifyContent: "center" }}>

        <Typography sx={{ mb: 2 }} fontSize={13}> Hi <b>{user && user?.name}</b>, Please contact your administrator to input the 2-step verification code to complete the process.</Typography>

        <BaseInput
          label="Verification Code"
          fullWidth
          value={secretCode}
          onChange={(e) => setSecretCode(e.target.value)}
          type="password"
        />
      </CardContent>
      <CardActions
        orientation="vertical"
        buttonFlex={1}
        sx={{
          '--Button-radius': '40px',
          width: 'clamp(min(100%, 160px), 50%, min(100%, 200px))',

        }}
      >

        <Button variant="solid"
          disabled={!secretCode}
          loading={loading}
          onClick={handleSubmitSecretCode}
        >
          Submit
        </Button>
        <br />
        <small>
          Abaco © 2024
        </small>
      </CardActions>
    </Card>
  </Box>
}

export default CompanyVerifyPage;