import { createSlice } from "@reduxjs/toolkit";
import { addListBuilderCase } from "redux-store/middlewares/builderUtils";
import { createCallApiAsyncAction } from "redux-store/middlewares/createAsyncAction";
import { persistEncryptReducer } from "redux-store/redux-utils";

import {
  cancelPurchaseOrderApi,
  createContainerApi,
  createPurchaseApi,
  deletePurchaseOrderApi,
  getContainerDetailApi,
  getDetailPurchaseApi,
  getListPurchaseSupplierApi,
  getListPurchaseWarehouseApi,
  getPOGenerateItemsApi,
  getTotalPOApi,
  listAssistedPurchaseApi,
  listContainerApi,
  listPurchaseApi,
  shipContainerAPi,
  updateCheckCostItemApi,
  updateContainerApi,
  updatePurchaseItemApi,
  updatePurchaseVendorApi,
} from "services/purchase.service";

import {
  IContainer,
  IPurchaseOrderList,
  TPurchaseSupplierList,
  TPurchaseWarehouseList,
} from "types/purchase";

const TYPE = "PURCHASE";

export interface IPurchaseState {
  list: IPurchaseOrderList;
  assistedList: IPurchaseOrderList;
  supplierList: TPurchaseSupplierList;
  warehouseList: TPurchaseWarehouseList;
  containerList: IContainer[];
}

const initialState: IPurchaseState = {
  list: [],
  assistedList: [],
  supplierList: [],
  warehouseList: [],
  containerList: [],
};

export const createPurchase = createCallApiAsyncAction(
  TYPE + "/createPurchase",
  createPurchaseApi
);

export const getListPurchase = createCallApiAsyncAction(
  TYPE + "/getListPurchase",
  listPurchaseApi
);

export const getListAssistedPurchase = createCallApiAsyncAction(
  TYPE + "/getListAssistedPurchase",
  listAssistedPurchaseApi
);

export const getTotalPO = createCallApiAsyncAction(
  TYPE + "/getTotalPO",
  getTotalPOApi
);

export const getDetailPurchase = createCallApiAsyncAction(
  TYPE + "/getDetailPurchase",
  getDetailPurchaseApi
);

export const getListPurchaseSupplier = createCallApiAsyncAction(
  TYPE + "/getListPurchaseSupplier",
  getListPurchaseSupplierApi
);

export const getListPurchaseWarehouse = createCallApiAsyncAction(
  TYPE + "/getListPurchaseWarehouse",
  getListPurchaseWarehouseApi
);

export const cancelPurchaseOrder = createCallApiAsyncAction(
  TYPE + "/cancelPurchaseOrder",
  cancelPurchaseOrderApi
);

export const deletePurchaseOrder = createCallApiAsyncAction(
  TYPE + "/deletePurchaseOrder",
  deletePurchaseOrderApi
);

export const updatePurchaseItem = createCallApiAsyncAction(
  TYPE + "/updatePurchaseItem",
  updatePurchaseItemApi
);

export const updatePurchaseVendor = createCallApiAsyncAction(
  TYPE + "/updatePurchaseVendor",
  updatePurchaseVendorApi
);

export const updateCheckCostItem = createCallApiAsyncAction(
  TYPE + "/updateCheckCostItem",
  updateCheckCostItemApi
);

export const listContainer = createCallApiAsyncAction(
  TYPE + "/listContainer",
  listContainerApi
);

export const createContainer = createCallApiAsyncAction(
  TYPE + "/createContainer",
  createContainerApi
);
export const updateContainer = createCallApiAsyncAction(
  TYPE + "/updateContainer",
  updateContainerApi
);

export const shipContainer = createCallApiAsyncAction(
  TYPE + "/shipContainer",
  shipContainerAPi
);

export const getContainerDetail = createCallApiAsyncAction(
  TYPE + "/getContainerDetail",
  getContainerDetailApi
);

export const getPOGenerateItems = createCallApiAsyncAction(
  TYPE + "/getPOGenerateItems",
  getPOGenerateItemsApi
);

const slice = createSlice({
  name: TYPE,
  initialState,
  extraReducers: (builder) => {
    addListBuilderCase({
      builder,
      fetchAction: getListPurchase,
      stateKey: "list",
    });
    addListBuilderCase({
      builder,
      fetchAction: getListAssistedPurchase,
      stateKey: "assistedList",
    });
    addListBuilderCase({
      builder,
      fetchAction: getListPurchaseSupplier,
      stateKey: "supplierList",
    });
    addListBuilderCase({
      builder,
      fetchAction: getListPurchaseWarehouse,
      stateKey: "warehouseList",
    });
    addListBuilderCase({
      builder,
      fetchAction: listContainer,
      stateKey: "containerList",
    });
  },
  reducers: {},
});

export default persistEncryptReducer(TYPE, slice.reducer, [
  "supplierList",
  "warehouseList",
]) as typeof slice.reducer;
