import { IContract } from "types/contract";
import { Request } from "utils/request";

export const getListContractApi = () => {
  return Request.call<{ data: IContract[] }>({
    url: "/ausa-contract/list",
    method: "GET",
  });
};

export const getDetailContractApi = (payload: { id: any }) => {
  return Request.call<{ data: IContract }>({
    url: "/ausa-contract/detail/" + payload.id,
    method: "GET",
  });
};

export const updateContractApi = (payload: any) => {
  return Request.call<{ data: IContract }>({
    url: "/ausa-contract/update/",
    method: "PUT",
    data: payload,
  });
};

export const actionContractApi = (payload: {
  id: any;
  action: "Approved" | "Sent";
}) => {
  return Request.call<{ data: IContract }>({
    url: "/ausa-contract/action/",
    method: "PUT",
    data: payload,
  });
};

export const createContractApi = (payload: IContract) => {
  return Request.call<{ data: IContract }>({
    url: "/ausa-contract/create",
    method: "PUT",
    data: payload,
  });
};
