import { IItemPallet, ILot } from "types/inventory";
import {
  IBoxSerialList,
  IPalletList,
  IPurchaseOrder,
  IPurchaseOrderApproval,
  IPurchaseOrderList,
} from "types/purchase";
import { Request } from "utils/request";


// box
export const getListBoxSerialApi = () => {
  return Request.call<{ data: IBoxSerialList }>({
    url: "/purchase-order/factory/box/list",
    method: "GET",
  });
};

export const createBoxSerialApi = (payload: any) => {
  return Request.call<{ data: any }>({
    url: "/purchase-order/factory/box/create",
    method: "PUT",
    data: payload,
  });
};

// pallet
export const getListPalletApi = () => {
  return Request.call<{ data: IPalletList }>({
    url: "/purchase-order/factory/pallet/list",
    method: "GET",
  });
};

export const getListPalletV2Api = (payload: { container_id: any }) => {
  return Request.call<{ data: IPalletList }>({
    url: "/purchase-order/factory/pallet/list/v2",
    method: "PUT",
    data: payload,
  });
};

export const getListPalletActiveApi = () => {
  return Request.call<{ data: IPalletList }>({
    url: "/purchase-order/factory/pallet/active",
    method: "GET",
  });
};

export const createPalletApi = (payload: any) => {
  return Request.call<{ data: any }>({
    url: "/purchase-order/factory/pallet/create",
    method: "PUT",
    data: payload,
  });
};

export const updatePalletApi = (payload: any) => {
  return Request.call<{ data: any }>({
    url: "/purchase-order/factory/pallet/update",
    method: "PUT",
    data: payload,
  });
};

export const assignPalletApi = (payload: any) => {
  return Request.call<{ data: any }>({
    url: "/purchase-order/factory/pallet/assign",
    method: "PUT",
    data: payload,
  });
};

export const assignMultiPalletApi = (payload: any) => {
  return Request.call<{ data: any }>({
    url: "/purchase-order/factory/pallet/multi-assign",
    method: "PUT",
    data: payload,
  });
};

export const assignMultiPalletV2Api = (payload: any) => {
  return Request.call<{ data: any }>({
    url: "/purchase-order/factory/pallet/multi-assign-v2",
    method: "PUT",
    data: payload,
  });
};

export const removePalletFromLotApi = (payload: any) => {
  return Request.call<{ data: any }>({
    url: "/purchase-order/factory/pallet/remove-assign",
    method: "PUT",
    data: payload,
  });
};

export const removePalletFromSerialApi = (payload: any) => {
  return Request.call<{ data: any }>({
    url: "/purchase-order/factory/pallet/remove-assign-v2",
    method: "PUT",
    data: payload,
  });
};

export const exportPalletApi = (payload: any) => {
  return Request.call<{ data: any }>({
    url: "/purchase-order/factory/pallet/export",
    method: "PUT",
    data: payload,
  });
};

export const removePalletApi = (payload: any) => {
  return Request.call<{ data: any }>({
    url: "/purchase-order/factory/pallet/remove",
    method: "PUT",
    data: payload,
  });
};

export const getListManufacturingApi = () => {
  return Request.call<{ data: IPurchaseOrderList }>({
    url: "/purchase-order/factory/list",
    method: "GET",
  });
};

export const getDetailManufacturingApi = (payload: { id: any }) => {
  return Request.call<{ data: IPurchaseOrder }>({
    url: "/purchase-order/factory/detail/" + payload.id,
    method: "GET",
  });
};

export const approveManufacturingApi = (payload: {
  po_id: any;
  workflow?: string;
  status?: string;

  eta?: string;
  etd?: string;
  shipping_cost?: string;
}) => {
  return Request.call<{
    data: {
      po_id: number;
    };
  }>({
    url: "/purchase-order/factory/approval/active/",
    method: "PUT",
    data: payload,
  });
};

export const denyManufacturingApi = (payload: {
  po_id: any;
  workflow?: string;
  deny_reason?: string;
}) => {
  return Request.call<{
    data: {
      po_id: number;
    };
  }>({
    url: "/purchase-order/factory/approval/deny/",
    method: "PUT",
    data: payload,
  });
};

export const getApprovalsManufacturingApi = (payload: { po_id: any }) => {
  return Request.call<{ data: IPurchaseOrderApproval[] }>({
    url: "/purchase-order/factory/approval/list/",
    method: "PUT",
    data: payload,
  });
};

export const getPOFactoryItemApi = (payload: {
  item_id: number;
  po_id: any;
}) => {
  return Request.call<{ data: ILot[] }>({
    url: "/purchase-order/factory/lot/items/",
    method: "PUT",
    data: payload,
  });
};

export const getPOFactoryItemV2Api = (payload: {
  item_id: number;
  po_id: any;
}) => {
  return Request.call<{ data: IItemPallet[] }>({
    url: "/purchase-order/factory/pallet/items/",
    method: "PUT",
    data: payload,
  });
};

export const resetSerialNumberV2Api = (payload: {
  item_id: number;
  po_id: any;
}) => {
  return Request.call<{ data: any }>({
    url: "/purchase-order/factory/pallet/reset-serial-number/",
    method: "PUT",
    data: payload,
  });
};

export const createFactoryLotApi = (payload: {
  po_id: any;
  qty: number;
  item_lot_id: number;
  item_id: number;
}) => {
  return Request.call<{ data: ILot[] }>({
    url: "/purchase-order/factory/lot/create/",
    method: "PUT",
    data: payload,
  });
};

export const moveFactoryItemToLotApi = (payload: {
  po_id: any;
  item_lot_id: any;
  serial_number: any;
}) => {
  return Request.call<{ data: ILot[] }>({
    url: "/purchase-order/factory/lot/assign/",
    method: "PUT",
    data: payload,
  });
};

export const deleteFactoryLotApi = (payload: { po_id: any; id: any }) => {
  return Request.call<{ data: ILot[] }>({
    url: "/purchase-order/factory/lot/delete/",
    method: "PUT",
    data: payload,
  });
};

export const splitLotPackageApi = (payload: any) => {
  return Request.call<{ data: any }>({
    url: "/purchase-order/factory/lot/split/",
    method: "PUT",
    data: payload,
  });
};

export const generateSerialNumberV2Api = (payload: {
  po_id: any;
  item_id: any;
  version: string;
  version_date: string;
  version_quantity: number;
  version_package?: string;
}) => {
  return Request.call<{ data: { serial_number: string } }>({
    url: "/purchase-order/factory/generate/serial/v2",
    method: "PUT",
    data: payload,
  });
};
