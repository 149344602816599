import { createSlice } from "@reduxjs/toolkit";
import { addListBuilderCase } from "redux-store/middlewares/builderUtils";
import { createCallApiAsyncAction } from "redux-store/middlewares/createAsyncAction";

import {
  approveManufacturingApi,
  assignMultiPalletApi,
  assignMultiPalletV2Api,
  assignPalletApi,
  createBoxSerialApi,
  createFactoryLotApi,
  createPalletApi,
  deleteFactoryLotApi,
  denyManufacturingApi,
  exportPalletApi,
  generateSerialNumberV2Api,
  getApprovalsManufacturingApi,
  getDetailManufacturingApi,
  getListBoxSerialApi,
  getListManufacturingApi,
  getListPalletActiveApi,
  getListPalletApi,
  getListPalletV2Api,
  getPOFactoryItemApi,
  getPOFactoryItemV2Api,
  moveFactoryItemToLotApi,
  removePalletApi,
  removePalletFromLotApi,
  removePalletFromSerialApi,
  resetSerialNumberV2Api,
  splitLotPackageApi,
  updatePalletApi
} from "services/manufacturing.service";
import { IPurchaseOrder } from "types/purchase";

const TYPE = "MANUFACTURING";

interface IManufacturingState {
  list: IPurchaseOrder[];
}

const initialState: IManufacturingState = {
  list: [],
};

// box
export const getListBoxSerial = createCallApiAsyncAction(
  TYPE + "/getListBoxSerial",
  getListBoxSerialApi
);
export const createBoxSerial = createCallApiAsyncAction(
  TYPE + "/createBoxSerial",
  createBoxSerialApi
);

// pallet
export const getListPallet = createCallApiAsyncAction(
  TYPE + "/getListPallet",
  getListPalletApi
);

export const getListPalletV2 = createCallApiAsyncAction(
  TYPE + "/getListPalletV2",
  getListPalletV2Api
);

export const getListPalletActive = createCallApiAsyncAction(
  TYPE + "/getListPalletActive",
  getListPalletActiveApi
);

export const createPallet = createCallApiAsyncAction(
  TYPE + "/createPallet",
  createPalletApi
);

export const updatePalletRequest = createCallApiAsyncAction(
  TYPE + "/updatePalletRequest",
  updatePalletApi
);

export const assignPallet = createCallApiAsyncAction(
  TYPE + "/assignPallet",
  assignPalletApi
);

export const exportPallet = createCallApiAsyncAction(
  TYPE + "/exportPallet",
  exportPalletApi
);

export const removePallet = createCallApiAsyncAction(
  TYPE + "/removePallet",
  removePalletApi
);

export const assignMultiPallet = createCallApiAsyncAction(
  TYPE + "/assignMultiPallet",
  assignMultiPalletApi
);

export const assignMultiPalletV2 = createCallApiAsyncAction(
  TYPE + "/assignMultiPalletV2",
  assignMultiPalletV2Api
);

export const removePalletFromLot = createCallApiAsyncAction(
  TYPE + "/removePalletFromLot",
  removePalletFromLotApi
);

export const removePalletFromSerial = createCallApiAsyncAction(
  TYPE + "/removePalletFromSerial",
  removePalletFromSerialApi
);

// manfacturing
export const getListManufacturing = createCallApiAsyncAction(
  TYPE + "/getListManufacturing",
  getListManufacturingApi
);

export const getDetailManufacturing = createCallApiAsyncAction(
  TYPE + "/getDetailManufacturing",
  getDetailManufacturingApi
);

export const approveManufacturing = createCallApiAsyncAction(
  TYPE + "/approveManufacturing",
  approveManufacturingApi
);

export const denyManufacturing = createCallApiAsyncAction(
  TYPE + "/denyManufacturing",
  denyManufacturingApi
);

export const getApprovalsManufacturing = createCallApiAsyncAction(
  TYPE + "/getApprovalsManufacturing",
  getApprovalsManufacturingApi
);

export const getPOFactoryItem = createCallApiAsyncAction(
  TYPE + "/getPOFactoryItem",
  getPOFactoryItemApi
);

export const getPOFactoryItemV2 = createCallApiAsyncAction(
  TYPE + "/getPOFactoryItemV2",
  getPOFactoryItemV2Api
);

export const resetSerialNumberV2 = createCallApiAsyncAction(
  TYPE + "/resetSerialNumberV2",
  resetSerialNumberV2Api
);

export const createFactoryLot = createCallApiAsyncAction(
  TYPE + "/createFactoryLot",
  createFactoryLotApi
);

export const moveFactoryItemToLot = createCallApiAsyncAction(
  TYPE + "/moveFactoryItemToLot",
  moveFactoryItemToLotApi
);

export const deleteFactoryLot = createCallApiAsyncAction(
  TYPE + "/deleteFactoryLot",
  deleteFactoryLotApi
);

export const splitLotPackage = createCallApiAsyncAction(
  TYPE + "/splitLotPackage",
  splitLotPackageApi
);

export const generateSerialNumberV2 = createCallApiAsyncAction(
  TYPE + "/generateSerialNumberV2",
  generateSerialNumberV2Api
);

const slice = createSlice({
  name: TYPE,
  initialState,
  extraReducers: (builder) => {
    addListBuilderCase({
      builder,
      fetchAction: getListManufacturing,
      stateKey: "list",
    });
  },
  reducers: {},
});

export default slice.reducer;
