import Box from "@mui/joy/Box";
import Header from "components/Header";
import Sidebar from "components/Sidebar";
import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import AnimationSkeleton from "./Loading";

export default function PrivateLayout() {
  return (
    <Box sx={{ display: "flex", minHeight: "100dvh" }}>
      <Header />
      <Sidebar />
      <Box
        component="main"
        className="MainContent"
        sx={{
          px: { xs: 2 },
          pt: "64px",
          pb: { xs: 2 },
          ml: {
            xs: 0,
            md: "calc(var(--SideNavigation-slideIn, 0) * 240px)",
          },
          transition: "margin 0.4s",
          // transform: {
          //   xs: "translateX(0)",
          //   md: "translateX(calc(var(--SideNavigation-slideIn, 0) * 240px))",
          // },
          flex: 1,
          display: "flex",
          flexDirection: "column",
          minWidth: 0,
          height: "100dvh",
          gap: 1,

          maxWidth: "100wh",
        }}
      >
        <Suspense fallback={<AnimationSkeleton />}>
          <Outlet />
        </Suspense>
      </Box>
    </Box>
  );
}
