import { IEmployee, IRole } from "types/employee";
import { Request } from "utils/request";

export const listEmployeeApi = () => {
  return Request.call<{ data: IEmployee[] }>({
    url: "/user/list",
    method: "GET",
  });
};

export const createEmployeeApi = (payload: IEmployee) => {
  return Request.call<{ data: IEmployee }>({
    url: "/user/create",
    method: "PUT",
    data: payload,
  });
};

export const updateEmployeeApi = (payload: IEmployee) => {
  return Request.call<{ data: IEmployee }>({
    url: "/user/update",
    method: "PUT",
    data: payload,
  });
};

export const getDetailEmployeeApi = (payload: { id: any }) => {
  return Request.call<{ data: IEmployee }>({
    url: "/user/" + payload.id,
    method: "GET",
  });
};

export const getRoleListApi = () => {
  return Request.call<{ data: IRole[] }>({
    url: "/user/role/list",
    method: "GET",
  });
};

export const updatePasswordApi = (payload: {
  password: string;
  re_password: string;
}) => {
  return Request.call<{ data: IEmployee }>({
    url: "/user/update-password",
    method: "PUT",
    data: payload,
  });
};

// quickbooks
export const getQuickBooksUrlApi = () => {
  return Request.call<{ data: any }>({
    url: "/user/quickbooks/auth",
    method: "GET",
  });
};

export const getUpsUrlApi = () => {
  return Request.call<{ data: any }>({
    url: "/user/ups/auth",
    method: "GET",
  });
};

// quickbooks
export const getQuickBooksTokenApi = (payload: any) => {
  return Request.call<{ data: any }>({
    url: "/user/quickbooks/auth/token",
    method: "PUT",
    data: payload,
  });
};

export const getUPSTokenApi = (payload: any) => {
  return Request.call<{ data: any }>({
    url: "/user/ups/auth/token",
    method: "PUT",
    data: payload,
  });
};

export const getEmailSettingsApi = () => {
  return Request.call<{ data: any }>({
    url: "/user/email-settings",
    method: "GET",
  });
};

export const updateEmailSettingsApi = (payload: {
  host: string;
  port: number;
  username: string;
  password: string;
  secure: boolean;
  pool: boolean;
}) => {
  return Request.call<{ data: any }>({
    url: "/user/email-settings",
    method: "PUT",
    data: payload,
  });
};
