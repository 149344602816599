import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import {
  postDeleteFirebaseTokenApi,
  postSaveFirebaseTokenApi,
} from "services/auth.service";
import AppEvent, { AppEventTypes, tabUUID } from "utils/app-events";
import notification from "utils/notification";

type TFirebaseEventItem = {
  data: {
    [key: string]: any;
    deviceid: string;
    type: AppEventTypes;
  };
  notification: {
    body: string;
    title: string;
  };
};

const firebaseConfig = {
  apiKey: process.env.REACT_APP_PUBLIC_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_PUBLIC_FIREBASE_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PUBLIC_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_PUBLIC_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_PUBLIC_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_PUBLIC_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_PUBLIC_FIREBASE_MEASUREMENTID
};

const firebaseApp = initializeApp(firebaseConfig);
const firebaseStorageKey = "TFIREBASE";

const handleMessage = (payload?: TFirebaseEventItem) => {
  if (!payload) return;

  notification.noti({
    title: payload?.notification?.title,
    body: payload?.notification?.body,
    action: () => {
      console.log("handleMessage", payload);
    },
  });
};
const handleListenMessage = (event: any) => {
  const payload = event.data as TFirebaseEventItem;
  if (payload?.data?.deviceid === tabUUID) return;
  if (payload?.data?.type) {
    AppEvent.emit(payload?.data?.type, payload.data);
  }
  // no need notify
  if ([AppEventTypes.NOTI_PULL].includes(payload?.data?.type)) return

  handleMessage(payload);

};
(window as any)._customTestEmit = (data: any) => {
  AppEvent.emit(data?.type, data);
};
export const initFirebase = async ({
  is_customer,
}: {
  is_customer: boolean;
}) => {
  const messaging = getMessaging(firebaseApp);
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      getToken(messaging, {
        vapidKey: process.env.REACT_APP_PUBLIC_RAPIDKEY,
      })
        .then(async (currentToken) => {
          const oldToken = localStorage.getItem(firebaseStorageKey);
          if (currentToken && currentToken !== oldToken) {
            if (oldToken) {
              await deleteTokenFirebase({ removeFromApp: true, is_customer });
            }
          }
          postSaveFirebaseTokenApi({
            token: currentToken,
            is_customer,
          }).then((rs) => {
            if (rs.apiStatus) {
              localStorage.setItem(firebaseStorageKey, currentToken);
            }
          });
        })
        .catch((err) => {
          const reloadError =
            "Failed to execute 'subscribe' on 'PushManager': Subscription failed - no active Service Worker";
          if (err?.toString?.().includes(reloadError)) {
            window.location.reload();
            return;
          }
          console.log("An error occurred while retrieving token. ", err);
        });
    } else {
      console.error("Unable to get permission to notify.");
    }
  });

  // this one handle when notification push to tab unfocus
  if (navigator.serviceWorker) {
    navigator.serviceWorker.removeEventListener("message", handleListenMessage);
    navigator.serviceWorker.addEventListener("message", handleListenMessage);
  }
};

export const deleteTokenFirebase = async ({
  removeFromApp,
  is_customer,
}: {
  removeFromApp: boolean;
  is_customer: boolean;
}) => {
  try {
    const oldToken = localStorage.getItem(firebaseStorageKey);
    if (oldToken) {
      if (removeFromApp) {
        postDeleteFirebaseTokenApi({
          token: oldToken,
          is_customer,
        });
      }
      localStorage.removeItem(firebaseStorageKey);
    }
  } catch (e) {
    console.log("firebase delete token error", e);
  }
};
