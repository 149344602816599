import { createSlice } from "@reduxjs/toolkit";
import { createCallApiAsyncAction } from "redux-store/middlewares/createAsyncAction";
import {
  createTaskApi,
  getDetailTaskApi,
  listTaskApi,
  updateTaskApi,
} from "services/task.service";
import { ITask } from "types/task";

const TYPE = "TASK";

export interface ITaskState {
  list: ITask[];
}

const initialState: ITaskState = {
  list: [],
};

export const createTask = createCallApiAsyncAction(
  TYPE + "/createTask",
  createTaskApi
);

export const updateTask = createCallApiAsyncAction(
  TYPE + "/updateTask",
  updateTaskApi
);

export const getListTask = createCallApiAsyncAction(
  TYPE + "/getListTask",
  listTaskApi
);

export const getDetailTask = createCallApiAsyncAction(
  TYPE + "/getDetailTask",
  getDetailTaskApi
);

const slice = createSlice({
  name: TYPE,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getDetailTask.fulfilled, (state, action) => {
      const taskDetail = action.payload?.response?.apiStatus
        ? action.payload?.response?.data
        : null;

      if (!taskDetail) return;

      const index = state.list.findIndex((item) => item.id === taskDetail.id);
      if (index > -1) {
        state.list[index] = taskDetail;
      }
    });
  },
  reducers: {},
});

export default slice.reducer;
